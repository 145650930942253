<template>
  <table>
    <thead v-if="this.id !== this.data.season_code">
      <tr>
        <td :class="noData">
          No Data Loaded Please Select a Season & Report
        </td>
      </tr>
    </thead>
    <thead v-else>
      <tr :class="{'adtcolorbk' : develop == 'adt'}">
        <th :class="[cell, fields]">
          Pos
        </th>
        <th :class="[cell, fields]" class="nameTitle">
          Name
        </th>
        <th :class="[cell, fields]">
          Nat
        </th>
        <th :class="[cell, fields]">
          {{ data.value_1_head }}
        </th>
        <th :class="[cell, fields]">
          Played
        </th>
      </tr>
    </thead>
    <tbody v-if="this.id !== this.data.season_code"></tbody>
    <tbody v-else>
      <tr v-if="data.oom_available == 'N'">
        <td colspan="5" id="NS">
          No Stats
        </td>
      </tr>
      <tr v-for="(stats, index) in data.oom.oom_entry" :key="index">
        <td v-if="data.oom_available == 'Y'" :class="[cell, name]">
          {{ stats.pos }}
        </td>
        <td
          v-if="data.oom_available == 'Y'"
          :class="[cell, name, {'adtcolour' : develop == 'adt'}]"
          class="memberName"
        >
        <template v-if="develop == 'adt'">
          <a :href="'/adt/playerprofile/' + stats.playerno">
            {{ stats.name }}
          </a>
        </template>
        <template v-else>
          <a :href="'/playerprofile/' + stats.playerno">
            {{ stats.name }}
          </a>
        </template>
          <b-img
            v-if="stats.sponsored === 'Y'"
            src="https://asian.ocs-asia.com/misc/titleist_oom.svg"
          ></b-img>
        </td>
        <td v-if="data.oom_available == 'Y'" :class="cell">
          <span>
            <img
              :class="flag"
              :src="
                (config.VUE_APP_FLAG_URL +
                  stats.nationality)
                  | lowercase
              "
            />
          </span>
        </td>
        <td v-if="data.oom_available == 'Y' && data.value_1_head === 'USD'" :class="cell">
          {{ stats.oom_value }}
        </td>
        <td v-else-if="data.oom_available == 'Y' && data.value_1_head !== 'USD'" :class="cell">
          {{ stats.oom_value }}
        </td>
        <td :class="cell" v-else>
          {{ stats.rounds_played }}
        </td>
        <td :class="cell">{{stats.tournaments_played}}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "statsTable",
  props: ["id", "stat", "data", "config", "develop"],
  data() {
    return {
      noData: "noData",
      flag: "flag",
      cell: "cell",
      fields: "fields",
      name: "name"
    };
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    }
  }
};
</script>

<style scoped>
::v-deep td.memberName.cell.name.adtcolour > a {
  color: #7E7E7E!important;
}
::v-deep td.memberName.cell.name.adtcolour > a:hover {
  color: #1c857c!important;
}
.adtcolorbk > th{
  background-color: #76c3bd;
}
.valueCol {
  margin-left: -22px;
}
.pos {
  /* margin-left: 22px; */
}
::v-deep a.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529!important;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
td.memberName {
  text-align: left;
}
th.nameTitle {
  text-align: left;
}
.noData {
  text-align: center;
  padding-top: 5%;
  padding-bottom: 5%;
}
.flag {
  width: 30px;
}
table {
  /* border-radius: 10px; */
  margin-bottom: 4em;
}
.fields {
  background-color: #133f7b;
  color: white;
  font-size: 13px !important;
}
.cell {
  width: 25%;
  text-align: center;
  padding: 9px;
  font-size: 14px;
}
tr:nth-child(even) {
  background-color: #F5F5F5;
  /* border-bottom: 1px solid #A8B7C9; */
}
tr:nth-child(odd) {
  background-color: white;
  /* border-bottom: 1px solid #A8B7C9; */
}
th {
  position: sticky;
  top: 0;
}
td {
  padding-top: 15px!important;
  padding-bottom: 15px!important;
  color: #7E7E7E;
}
::v-deep td.memberName >a {
  color: #0A3F7F!important;
  font-weight: 500;
}
::v-deep td.memberName >a:hover {
  color: #5897E6!important;
  font-weight: 500;
  text-decoration: none;
}
::v-deep th:first-child {
  /* border-top-left-radius: 15px; */
}
::v-deep th:last-child {
  /* border-top-right-radius: 15px; */
}

#NS {
  text-align: center;
  font-size: 1.5rem;
  padding-top: 2%;
  padding-bottom: 2%;
}

@media only screen and (max-width: 480px) {
  .noData {
    height: 50vh;
  }
  .cell {
    width: 1%;
  }
  .flag {
    width: 100%;
  }
  .name {
    width: 3%;
  }
  table {
    border-radius: 10px;
    margin-bottom: 4em;
    margin-top: 2em;
  }
}
</style>
